import React, { ReactNode } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { useStyleConfig } from '@chakra-ui/system'

type CardWrapperSize = 'sm' | 'full'

type CardWrapperProps = { children: ReactNode; size?: CardWrapperSize } & BoxProps

export const CardWrapper = ({ children, size, ...boxProps }: CardWrapperProps) => {
    const styles: any = useStyleConfig('CardWrapper', { size })

    return (
        <Box __css={styles} display={'flex'} flexDirection={'column'} {...boxProps}>
            {children}
        </Box>
    )
}

import { NextComponentType } from 'next'
import { NextUrqlPageContext } from 'next-urql'
import { AnalyticsProvider } from 'hooks/useAnalytics'

export const withAnalytics = ({
    pageNameForAnalytics,
    pageVersionForAnalytics,
}: {
    pageNameForAnalytics: string
    pageVersionForAnalytics?: string
}) => (Component: NextComponentType) => {
    const Comp = ({ ...props }: any) => {
        return (
            <AnalyticsProvider
                pageNameForAnalytics={pageNameForAnalytics}
                pageVersionForAnalytics={pageVersionForAnalytics}
            >
                <Component {...props} />
            </AnalyticsProvider>
        )
    }
    Comp.getInitialProps = async (ctx: NextUrqlPageContext) => {
        return Component.getInitialProps && (await Component.getInitialProps(ctx))
    }
    return Comp
}

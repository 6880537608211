import { useState } from 'react'
import {
    Flex,
    Icon,
    IconButton,
    LinkBox,
    LinkOverlay,
    Skeleton,
    Text,
    TextProps,
    chakra,
} from '@chakra-ui/react'
import Link from 'next/link'
import { Clock as ClockIcon, Globe as GlobIcon } from '@emotion-icons/feather'
import { Bookmark as BookmarkIcon } from '@emotion-icons/feather/Bookmark'
import { ResultOf } from '@graphql-typed-document-node/core'
import { gql } from '@urql/core'
import { formatDistanceToNow } from 'date-fns'
import { Company } from 'entities/company'
import { Job, RoleType } from 'entities/job'
import { job as mockJob } from 'entities/job.mock'
import { Remuneration, formatRemuneration } from 'entities/remuneration'
import { Tag } from 'entities/tag'
import { Me } from 'entities/user'
import { routes } from 'routes'
import { useIsLikedJob, useLikedJobs } from 'hooks/useLikedJobs'
import useMe from 'hooks/useMe'
import { CardWrapper } from 'components/CardWrapper'
import { Heading } from 'components/Heading'
import { Flair } from 'components/flair/Flair'
import { Avatar } from 'components/images/Avatar'
import { Tags } from 'components/tags/Tags'
import CompensationIcon from 'public/img/compensation_icon.svg'

const GlobeIconChakra = chakra(GlobIcon)
const CompensationIconChakra = chakra(CompensationIcon)
const ClockIconChakra = chakra(ClockIcon)

export const FRAGMENT_JOB_MINI_CARD = gql<
    Pick<
        Job,
        | 'id'
        | 'title'
        | 'remote'
        | 'slug'
        | 'lastModified'
        | 'roleType'
        | 'remuneration'
        | 'published'
    > & {
        // fetch weight for sorting
        tags: Pick<Tag, 'id' | 'slug' | 'label' | 'weight'>[]
        company: Pick<Company, 'id' | 'logo' | 'name'>
    }
>`
    fragment JobMiniCard on Job {
        id
        title
        remote
        slug
        lastModified
        roleType
        published
        remuneration {
            competitive
            min
            max
            currency
            timePeriod
            equity
        }
        tags {
            id
            slug
            label
            weight
        }
        company {
            id
            logo
            name
        }
    }
`

export type JobMiniCardData = ResultOf<typeof FRAGMENT_JOB_MINI_CARD>

export const JobMiniCardTags = ({
    tags,
    isLoaded,
}: {
    isLoaded: boolean
    tags: JobMiniCardData['tags']
}) => (
    <Tags
        tags={tags}
        buildHref={({ slug }) => routes.jobsPresetSearch({ tech: { slug } })}
        isLoaded={isLoaded}
        // wrap props
        maxH="72px"
        overflow="hidden"
        mb="13px"
        // tag props
        variant="secondary"
        size="md"
    />
)

export const JobMiniCardRemuneration = ({
    remuneration,
    variant = 'functionalNormal',
}: {
    remuneration?: Remuneration
    variant?: TextProps['variant']
}) => {
    if (!remuneration) {
        return null
    }
    return (
        <Flex gridGap="4px" align="center">
            <CompensationIconChakra boxSize="17px" strokeWidth={1.5} />
            <Text variant={variant}>{formatRemuneration(remuneration)}</Text>
        </Flex>
    )
}

export const JobMiniCardRoleType = ({ roleType }: { roleType?: RoleType }) => {
    if (!roleType) {
        return null
    }
    return (
        <Flex gridGap="5px" align="center">
            <ClockIconChakra boxSize="16px" strokeWidth={1.5} />
            <Text variant="functionalNormal" whiteSpace="nowrap">
                {roleType}
            </Text>
        </Flex>
    )
}

export const JobMiniCardRemote = ({ remote }: { remote?: boolean }) => {
    if (!remote) {
        return null
    }
    return (
        <Flex gridGap="5px" align="center">
            <GlobeIconChakra boxSize="16px" strokeWidth={1.5} />
            <Text variant="functionalNormal">Remote</Text>
        </Flex>
    )
}
export const JobMiniCardFooter = ({
    isLoaded,
    me,
    job,
}: {
    isLoaded: boolean
    me?: Me
    job?: JobMiniCardData
}) => {
    const showLikeButton = me && isLoaded && job && job.published
    const lastModified = job?.lastModified
        ? formatDistanceToNow(new Date(job.lastModified), { addSuffix: true })
        : mockJob.lastModified

    return (
        <Flex justifyContent="space-between" mt={'auto'}>
            <Skeleton isLoaded={isLoaded} alignSelf="center" w="fit-content">
                <Text variant="functionalNormal" color="text.secondary">
                    {lastModified}
                </Text>
            </Skeleton>
            {showLikeButton && <LikeJob job={job} />}
        </Flex>
    )
}

export const LikeJob = ({ job }: { job: Pick<Job, 'id'> }) => {
    const [isLiking, setIsLiking] = useState<boolean>(false)
    const { likeJob } = useLikedJobs()
    const isLiked = useIsLikedJob(job.id)
    const jobId = job.id

    const handleLike = () => {
        setIsLiking(true)
        likeJob({
            id: jobId,
            action: isLiked ? 'unlike' : 'like',
        }).then(() => {
            setIsLiking(false)
        })
    }

    return (
        <IconButton
            onClick={handleLike}
            variant="custom"
            size="custom"
            fontSize="24px"
            mr="-5px"
            mb="-3px"
            title={isLiked ? 'Remove saved job' : 'Save job'}
            aria-label={isLiked ? 'Remove saved job' : 'Save job'}
            _hover={{ color: 'text.secondary' }}
            _active={{ color: 'text.secondary' }}
            icon={
                <Icon
                    as={BookmarkIcon}
                    strokeWidth="1.5px"
                    stroke={!isLiked ? 'gray.graphics_dark' : 'secondary.500'}
                    fill={!isLiked ? 'white' : 'secondary.500'}
                    _hover={{
                        fill: !isLiked ? 'gray.dividers' : '',
                    }}
                    _focus={{
                        stroke: 'secondary.500',
                    }}
                />
            }
            disabled={isLiking}
        />
    )
}

export const JobMiniCard = ({ job }: { job?: JobMiniCardData }) => {
    const me = useMe()

    const {
        slug = mockJob.slug,
        title = mockJob.title,
        company: {
            logo: companyLogo = mockJob.company.logo,
            name: companyName = mockJob.company.name,
        } = mockJob.company ?? {},
        remuneration = mockJob.remuneration ?? {},
        roleType = mockJob.roleType,
        remote = mockJob.remote,
        tags = mockJob.tags,
        published = mockJob.published,
    } = job ?? {}

    const isLoaded = Boolean(job?.id)
    const jobURL = isLoaded ? routes.job({ slug }) : '#'

    return (
        <LinkBox
            as={CardWrapper}
            size="full"
            p="20px"
            alignContent="space-between"
            gridGap="16px"
            position={'relative'}
        >
            <Flex gridGap="17px" align="center">
                {companyLogo && (
                    <Avatar
                        variant="company"
                        src={companyLogo}
                        boxSize="36px"
                        isLoaded={isLoaded}
                    />
                )}
                <Skeleton isLoaded={isLoaded}>
                    <Link href={jobURL} passHref>
                        <LinkOverlay w="fit-content">
                            <Heading as="h3" size="sm" letterSpacing={0} noOfLines={1}>
                                {companyName}
                            </Heading>
                        </LinkOverlay>
                    </Link>
                </Skeleton>
            </Flex>
            {isLoaded && !published && (
                <Flair
                    variant="alert"
                    size="sm"
                    position={'absolute'}
                    right={'8px'}
                    top={'8px'}
                >
                    Unpublished
                </Flair>
            )}
            <Skeleton isLoaded={isLoaded} w="fit-content" mb="2px">
                <Heading as="h4" size="sm" noOfLines={2}>
                    {title}
                </Heading>
            </Skeleton>

            <Skeleton isLoaded={isLoaded} w="fit-content" mb="4px">
                <Flex flexWrap="wrap" gridColumnGap="16px" gridRowGap="10px">
                    <JobMiniCardRemuneration remuneration={remuneration} />
                    <JobMiniCardRoleType roleType={roleType} />
                    <JobMiniCardRemote remote={remote} />
                </Flex>
            </Skeleton>

            <JobMiniCardTags tags={tags} isLoaded={isLoaded} />
            <JobMiniCardFooter isLoaded={isLoaded} me={me} job={job} />
        </LinkBox>
    )
}

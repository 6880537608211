import { ATS } from 'entities/ats'
import { Location } from 'entities/location'
import { Permission } from 'entities/permission'
import { Tag } from 'entities/tag'
import { nonBreakingItemsJoin } from 'lib/text'

// interfaces

export interface Company {
    id: string
    name: string
    slug: string
    logo?: string
    description?: string
    descriptionHtml?: string
    tags?: Tag[]
    locations?: CompanyLocation[]
    applicationStateFrequencies?: [
        {
            state: 'approved' | 'pending' | 'rejected' | 'get_in_touch' | 'pass' | 'hired'
            count: number
        },
    ]
    integrations?: {
        [ATS.Greenhouse]?: Integration
        [ATS.Workable]?: Integration
    }
    permissions?: Permission[]
    profileEnabled: boolean
    totalPublishedJobCount: number
    pendingOffer?: Offer
    offer?: Offer
    size?: CompanySize
    manager?: string
}

export type Offer = {
    placementPercentage: number
    recurringFee: number
    acceptedAt?: string
}

export const CompanySizes = {
    micro: '1-9',
    small: '10-49',
    medium: '50-249',
    large: '250+',
} as const

export type CompanySize = keyof typeof CompanySizes

export type CompanyLocation = Pick<Location, 'city' | 'country'>

export type Integration = {
    enabled: boolean
}

// helper functions

export const getCompanyMainLocationCountry = ({
    locations = [],
}: {
    locations?: CompanyLocation[]
} = {}) => {
    const mainLocation = locations[0]
    return nonBreakingItemsJoin([mainLocation?.city, mainLocation?.country])
}

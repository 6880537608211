export const locationUS = {
    street: '',
    city: 'San Francisco',
    country: 'United States',
    countryCode: 'US',
    state: 'CA',
    postCode: '95125',
    longitude: -122.4194155,
    latitude: 37.7749295,
}
export const locationUK = {
    street: '68 Hanbury Street',
    city: 'London',
    country: 'United Kingdom',
    countryCode: 'GB',
    state: '',
    postCode: 'E1 5JL',
    longitude: -0.07035870000004252,
    latitude: 51.5201187,
}
export const locationEU = {
    street: '',
    city: 'Zürich',
    country: 'Switzerland',
    countryCode: 'CH',
    state: '',
    postCode: '7750',
    longitude: 8.541694,
    latitude: 47.3768866,
}

export const manyLocations = [locationUS, locationUK, locationEU]

export const skeletonLocation = {
    country: 'Fairy Wonderland',
}

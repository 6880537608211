import { CompanySize } from 'entities/company'
import { manyLocations } from 'entities/location.mock'
import { tags } from 'entities/tag.mock'

export const company = {
    id: 'audius-f4df1',
    slug: 'audius',
    name: 'Audius',
    logo:
        'https://functionalworks-backend--prod.s3.amazonaws.com/logos/1995a4e477636193ee0175c388237436',
    size: 'small' as CompanySize,
    description:
        'Audius is a digital streaming service that connects fans directly with artists and exclusive new music. It does this by being fully decentralized: Audius is owned and run by a vibrant, open-source community of artists, fans, and api all around the world. Audius gives artists the power to share never-before-heard music and monetize streams directly. With the Audius API, developers can build their own apps on top of Audius, giving them access to one of the most unique audio catalogs in existence. Backed by an all-star team of investors (including Katy Perry, Steve Aoki and Nas), Audius was founded in 2018 and serves over 6 million users every month, making it the largest non-financial crypto application ever built.',
    descriptionHtml:
        '<p>Audius is a digital streaming service that connects fans directly with artists and exclusive new music.</p><p>It does this by being fully decentralized: Audius is owned and run by a vibrant, open-source community of artists, fans, and api all around the world. Audius gives artists the power to share never-before-heard music and monetize streams directly. With the Audius API, developers can build their own apps on top of Audius, giving them access to one of the most unique audio catalogs in existence.</p><p>Backed by an all-star team of investors (including Katy Perry, Steve Aoki and Nas), Audius was founded in 2018 and serves over 6 million users every month, making it the largest non-financial crypto application ever built.</p>',
    profileEnabled: true,
    totalPublishedJobCount: 7,
    tags,
    locations: manyLocations,
}

import React, { createContext, ReactNode, useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { gql } from '@urql/core'
import { useMutation } from 'urql'
import { Job } from 'entities/job'
import { User } from 'entities/user'
import { isServerSide } from 'lib/env'
import useMe from 'hooks/useMe'
import useQuery from 'hooks/useQuery'

export type LikedJobsContextValue = {
    likedJobsIds: Job['id'][]
    likeJob: ({
        id,
        action,
    }: {
        id: Job['id']
        action: 'like' | 'unlike'
    }) => Promise<any>
}

const LikedJobsContext = createContext<LikedJobsContextValue | null>(null)

const QUERY_LIKED_JOBS = gql<{
    me: {
        id: User['id']
        likes: {
            id: Job['id']
        }[]
    }
}>`
    query liked_jobs {
        me {
            id
            likes {
                ... on Job {
                    id
                }
            }
        }
    }
`

const MUTATION_LIKE_JOB = gql<{
    marked: boolean
}>`
    mutation mark_entity($id: String!, $add: Boolean) {
        mark_entity(id: $id, add: $add, action: like, entity_type: job) {
            marked
        }
    }
`

export const WithLikedJobs = ({
    children,
    defaultLikedJobIds,
}: {
    children: ReactNode
    defaultLikedJobIds?: Job['id'][]
}) => {
    const me = useMe()

    const [result, reExecuteQuery] = useQuery({
        query: QUERY_LIKED_JOBS,
        pause: isServerSide || !me,
    })

    const [, likeJobHandler] = useMutation(MUTATION_LIKE_JOB)

    const toast = useToast()

    const likeJob = async ({
        id,
        action,
    }: {
        id: Job['id']
        action: 'like' | 'unlike'
    }) => {
        const shouldLike = action === 'like'
        const result = await likeJobHandler({
            id,
            add: shouldLike,
        })

        if (result?.error) {
            toast({
                title: `Sorry, something went wrong.`,
                description: `Unable to ${shouldLike ? 'save' : 'remove saved'} job`,
                status: 'error',
            })
        } else {
            reExecuteQuery({ requestPolicy: 'network-only' })
        }
    }

    const likedJobsIds = useMemo(() => {
        return result?.data?.me?.likes?.map(like => like.id) ?? []
    }, [result?.data])

    return (
        <LikedJobsContext.Provider
            value={{
                likedJobsIds: defaultLikedJobIds || likedJobsIds,
                likeJob,
            }}
        >
            {children}
        </LikedJobsContext.Provider>
    )
}

export function useLikedJobs(): LikedJobsContextValue {
    const context = React.useContext(LikedJobsContext)
    if (!context) {
        throw new Error('Please provide LikedJobsContext')
    }
    return context
}

export function useIsLikedJob(id: Job['id']) {
    const { likedJobsIds } = useLikedJobs()

    return useMemo(() => {
        return likedJobsIds?.includes(id)
    }, [likedJobsIds, id])
}

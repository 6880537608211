import { NextComponentType, NextPageContext } from 'next'
import Cookies from 'universal-cookie'
import { isServerSide } from 'lib/env'
import { CookiesProvider } from 'hooks/useCookies'

export const withCookies = (Component: NextComponentType) => {
    const Comp = ({ cookies, ...otherProps }: { cookies: object }) => {
        return (
            <CookiesProvider cookiesPlainObject={cookies}>
                <Component {...otherProps} />
            </CookiesProvider>
        )
    }
    Comp.getInitialProps = async (ctx: NextPageContext) => {
        const props = Component.getInitialProps && (await Component.getInitialProps(ctx))
        return {
            ...props,
            cookies: isServerSide
                ? new Cookies(ctx.req?.headers?.cookie).getAll()
                : new Cookies().getAll(),
        }
    }
    return Comp as NextComponentType<NextPageContext, { cookies: object }>
}

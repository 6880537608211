import React from 'react'
import { NextComponentType, NextPageContext } from 'next'
import getConfig from 'next/config'
import { ParsedUrlQuery } from 'querystring'
import { StandardVertical } from 'entities/vertical'
import { CtxRequestWithHeaders } from 'lib/contextNext'
import { isServerSide } from 'lib/env'
import { allVerticalsConfig, extractVertical, getVerticalUrl } from 'lib/vertical'
import { VerticalContext, VerticalData } from 'hooks/useVerticalData'

const { NODE_ENV } = getConfig()?.publicRuntimeConfig ?? {}

export function getVerticalDataPure(
    hostname: string,
    protocol: 'http' | 'https',
    query: ParsedUrlQuery,
) {
    const origin = `http://${hostname}`

    const url = (vertical: StandardVertical) => getVerticalUrl(protocol, origin, vertical)

    const allVerticalUrls: Record<StandardVertical, string> = {
        functional: url('functional'),
        javascript: url('javascript'),
        java: url('java'),
        blockchain: url('blockchain'),
        remote: url('remote'),
        ai: url('ai'),
        golang: url('golang'),
        python: url('python'),
        www: url('www'),
    }

    const vertical = extractVertical(hostname, query)
    const verticalConfig = allVerticalsConfig[vertical]

    return {
        allVerticalUrls,
        vertical,
        verticalConfig,
    }
}

export function getVerticalData(
    ctx: CtxRequestWithHeaders & Pick<NextPageContext, 'query'>,
): VerticalData {
    return getVerticalDataPure(
        isServerSide ? ctx?.req?.headers?.host! : window.location.hostname!,
        NODE_ENV === 'development' ? 'http' : 'https',
        ctx.query,
    )
}

function withVertical(Component: NextComponentType) {
    const WrappedComponent = ({
        verticalData,
        ...props
    }: { verticalData: React.ContextType<typeof VerticalContext> } & any) => {
        return (
            <VerticalContext.Provider value={verticalData}>
                <Component {...props} />
            </VerticalContext.Provider>
        )
    }

    WrappedComponent.getInitialProps = async (ctx: NextPageContext) => {
        const otherProps =
            Component.getInitialProps && (await Component.getInitialProps(ctx))

        return {
            ...otherProps,
            verticalData: getVerticalData(ctx),
        }
    }

    return WrappedComponent
}

export default withVertical

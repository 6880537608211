import { difference } from 'ramda'

export type Permission =
    | 'can_publish'
    | 'can_see_applications'
    | 'can_upgrade'
    | 'can_start_free_trial'
    | 'can_use_integrations'
    | 'can_cancel_subscription'
    | 'can_add_user'
    | 'can_edit_jobs'
    | 'can_edit_jobs_after_first_job_published'
    | 'can_create_job'

export const hasRequiredPermissions = (
    requiredPermissions: Permission[],
    presentPermissions?: Permission[],
) => {
    return difference(requiredPermissions, presentPermissions ?? []).length === 0
}

import React from 'react'
import { NextComponentType } from 'next'
import { NextUrqlPageContext } from 'next-urql'
import { Company } from 'entities/company'
import { Permission, hasRequiredPermissions } from 'entities/permission'
import { Me, User, UserType } from 'entities/user'
import { routes } from 'routes'
import { isServerSide } from 'lib/env'
import { navigateToOldFrontEnd } from 'lib/navigation'
import { ssrRedirect } from 'lib/pageContext'
import { baseUrl } from 'lib/url'
import { MeContext, fetchMe } from 'hooks/useMe'

type UserCheckAuth =
    | (Pick<User, 'type'> & { company?: Pick<Company, 'permissions'> })
    | null

function redirectUrl(me: Me, ctx?: NextUrqlPageContext) {
    if (me) {
        return baseUrl(ctx)
    }
    let redirect = isServerSide ? ctx?.asPath : window.location.pathname
    // if we are not logged in, redirect to a login page with a redirect param
    // there is possibility that a user has rights to access this page
    // so we want to redirect him after a successful login
    return `${baseUrl(ctx)}${routes.login({ redirect })}`
}

type PermissionRules = { requiredPermissions: Permission[]; redirectUrl: string }
type AuthRules =
    | { allowedUserTypes: UserType[]; permissionRules?: PermissionRules }
    | { isPublicPage: true }

export function userAuthorizationDescription(user: UserCheckAuth, authRules: AuthRules) {
    let isTypeCorrect = isUserTypeCorrect(user, authRules)
    let isPermissionsCorrect = isUserPermissionsCorrect(user, authRules)
    return {
        isUserAuthorized: isPermissionsCorrect && isTypeCorrect,
        isUserTypeCorrect: isTypeCorrect,
        isUserPermissionsCorrect: isPermissionsCorrect,
    }
}

export function isUserAuthorized(user: UserCheckAuth, authRules: AuthRules): boolean {
    return userAuthorizationDescription(user, authRules).isUserAuthorized
}

export function isUserTypeCorrect(user: UserCheckAuth, rules: AuthRules): boolean {
    if ('isPublicPage' in rules) {
        return true
    }
    const type = user?.type
    if (type) {
        return rules.allowedUserTypes.includes(type)
    } else {
        return false
    }
}

export function isUserPermissionsCorrect(user: UserCheckAuth, rules: AuthRules): boolean {
    if ('isPublicPage' in rules) {
        return true
    }
    const type = user?.type
    if (type && type === UserType.Company && rules.permissionRules) {
        return hasRequiredPermissions(
            rules.permissionRules.requiredPermissions,
            user?.company?.permissions,
        )
    }
    return true
}

const withAuth = (rules: AuthRules) => (WrappedComponent: NextComponentType) => {
    const Comp = ({
        isAuthorized,
        me,
        ...props
    }: { isAuthorized: boolean; me: React.ContextType<typeof MeContext> } & any) => {
        if (isAuthorized) {
            return (
                <MeContext.Provider value={me}>
                    <WrappedComponent {...props} />
                </MeContext.Provider>
            )
        } else {
            return null
        }
    }
    Comp.getInitialProps = async (ctx: NextUrqlPageContext) => {
        const me = await fetchMe(ctx.urqlClient, ctx)
        const authDescription = userAuthorizationDescription(me, rules)

        if (!authDescription.isUserTypeCorrect) {
            if (isServerSide) {
                ssrRedirect(ctx, redirectUrl(me, ctx))
            } else {
                navigateToOldFrontEnd(redirectUrl(me))
            }
        }

        if (
            'permissionRules' in rules &&
            rules.permissionRules &&
            !authDescription.isUserPermissionsCorrect
        ) {
            const url = rules.permissionRules.redirectUrl
            if (isServerSide) {
                ssrRedirect(ctx, url)
            } else {
                navigateToOldFrontEnd(url)
            }
        }

        const otherProps = authDescription.isUserAuthorized
            ? WrappedComponent.getInitialProps &&
              (await WrappedComponent.getInitialProps(ctx))
            : {}

        return { ...otherProps, isAuthorized: authDescription.isUserAuthorized, me }
    }
    return Comp
}

export default withAuth

import React, { ReactElement, ReactNode } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { useStyleConfig } from '@chakra-ui/system'

export type FlairProps = {
    children: ReactNode
    icon?: ReactElement
    variant?: string
    size?: 'sm' | 'md'
} & BoxProps

export const Flair = ({ children, icon, variant, size, ...boxProps }: FlairProps) => {
    const styles: any = useStyleConfig('Flair', { size, variant })

    return (
        <Box __css={styles} {...boxProps}>
            <>{icon}</>
            {children}
        </Box>
    )
}
